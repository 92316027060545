import React from 'react';
import Text from 'components/Core/Text';
import WebsiteImage from 'components/WebsiteImage';
import heroBgImg from 'images/tmp-webinar-series-hero-bg.jpg';
import heroLogoImg from 'images/tmp-webinar-series-logo.png';
import { SectionContainer } from './styles';

const Hero = ({ ...otherProps }) => {
  return (
    <SectionContainer backgroundImg={heroBgImg} {...otherProps}>
      <div className="webinar-series-page-hero--wrapper">
        <div className="webinar-series-page-hero--container">
          <h1 className="webinar-series-page-hero--title">
            <WebsiteImage loading="eager" image={heroLogoImg} />
          </h1>
          <Text type="p" className="webinar-series-page-hero--description">
            ServiceTitan’s 8-week masterclass series for the trades. Our lineup of industry experts
            teaches strategies for scaling your business — the right way.
          </Text>
        </div>
      </div>
    </SectionContainer>
  );
};

export default Hero;
