import React, { useState, useEffect } from 'react';
import Text from 'components/Core/Text';
import getVideoData from 'services/wistiaVideoService';
import CardSubhead from './Subhead';
import CardFooter from './Footer';
import { DetailCardContainer } from './styles';

const SeriesDetailCard = ({
  info,
  isFormSubmitted,
  handleOpenRegisterModal,
  handleOpenLiveModal,
  handleNoVideo,
  isVisible,
  ...otherProps
}) => {
  const [selSpeaker, setSelSpeaker] = useState(0);
  const [duration, setDuration] = useState(0);
  const { webinarTitle, keyTakeaways, speakers, webinarUTCDate, wistiaVideoId } = info;
  const selSpeakerItem = speakers && speakers[selSpeaker];
  const logo = selSpeakerItem?.company?.logo?.fullColor?.image?.file?.url;
  const imageMobile = selSpeakerItem?.photo?.imageMobile?.file?.url;
  const imageDesktop = selSpeakerItem?.photo?.image?.file?.url;
  const speakerName = `${selSpeakerItem?.firstName} ${selSpeakerItem?.lastName}`.trim();

  useEffect(() => {
    if (isVisible && speakers && speakers.length > 1) {
      const timer = setTimeout(() => {
        setSelSpeaker((selSpeaker + 1) % speakers.length);
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
    return () => {};
  }, [selSpeaker, isVisible, speakers]);

  const handleVideoClick = () => {
    otherProps?.handleVideoClick(webinarTitle);
  };

  useEffect(() => {
    (async () => {
      const formattedUrl = `https://servicetitan-1.wistia.com/medias/${wistiaVideoId}`;
      const data = await getVideoData(formattedUrl);
      const durationMins = Math.floor(data.duration / 60);
      setDuration(durationMins);
    })();
  }, []);

  return (
    <DetailCardContainer
      speakerPhoto={imageDesktop}
      speakerPhotoMobile={imageMobile}
      {...otherProps}
    >
      <div className="webinar-series-detail-card--wrapper">
        <div className="webinar-series-detail-card--photo">
          <div className="webinar-series-detail-card--speakers-container">
            {speakers &&
              speakers.length > 1 &&
              speakers.map((item, index) => (
                <div
                  className={`webinar-series-detail-card--speakers-item ${
                    index === selSpeaker ? 'selected' : ''
                  }`}
                  style={{ backgroundImage: `url(${item.photo?.image?.file?.url})` }}
                  key={index}
                />
              ))}
          </div>
        </div>
        <div className="webinar-series-detail-card--content-container">
          <div className="webinar-series-detail-card--text-container">
            <CardSubhead date={webinarUTCDate} duration={duration} companyLogo={logo} />

            <div className="webinar-series-detail-card--main-text">
              <Text className="webinar-series-detail-card--heading" type="h4">
                {webinarTitle}
              </Text>
              {speakerName && (
                <Text className="webinar-series-detail-card--author" type="p">
                  {speakerName}
                </Text>
              )}
              <ul className="webinar-series-detail-card--features">
                {keyTakeaways &&
                  keyTakeaways.length > 0 &&
                  keyTakeaways.map((item, index) => <li key={index}>{item}</li>)}
              </ul>
            </div>

            <CardFooter
              info={info}
              isFormSubmitted={isFormSubmitted}
              handleOpenRegisterModal={handleOpenRegisterModal}
              handleOpenLiveModal={handleOpenLiveModal}
              handleNoVideo={handleNoVideo}
              handleVideoClick={handleVideoClick}
            />
          </div>
        </div>
      </div>
    </DetailCardContainer>
  );
};

export default SeriesDetailCard;
