/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { MenuItem } from '@mui/material';

export const CalendarMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root:hover {
    background: #f5fcff;
  }

  .calendar-link {
    font-family: var(--font-family-body);
    font-size: var(--font-size-16);
    line-height: var(--line-height-150);
    color: inherit;
    padding: 6px 16px;
    margin: -6px -16px;
    display: flex;
    align-items: center;
    width: calc(100% + 32px);

    svg {
      margin-right: 10px;
      font-size: var(--font-size-20);
    }
  }
`;
