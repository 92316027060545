/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const SectionContainer = styled.div`
  background: white;

  .webinar-series-page-hero--wrapper {
    background: ${(props) => `url(${props.backgroundImg || ''})`};
    background-repeat: no-repeat;
    background-size: cover;
  }

  .webinar-series-page-hero--container {
    margin: 0 auto;
    padding: 80px 0 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .webinar-series-page-hero--title {
      padding: 0 28px;
      margin: 0 auto;
      text-align: center;

      img {
        width: 300px;
        max-width: 100%;
      }
    }

    .webinar-series-page-hero--description {
      font-size: var(--font-size-16);
      color: var(--white);
      text-align: center;
      max-width: 510px;
      margin: 0 auto;
      margin-top: 25px;
      padding: 0 28px;
    }
  }

  @media (max-width: 767px) {
    .webinar-series-page-hero--container {
      .webinar-series-page-hero--title {
        img {
          max-width: 80%;
        }
      }
      .webinar-series-page-hero--description {
        font-size: var(--font-size-16);
        margin-top: 16px;
      }
    }
  }
`;
