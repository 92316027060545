import React from 'react';
import dayJs, { webinarTimeCompare, webinarTimeDurationString } from 'utils/date';
import WebsiteImage from 'components/WebsiteImage';
import { STIcon } from 'components/Brand/Icons/STIcon';

import { SeriesDetailCardSubheadContainer } from './styles';

const SeriesDetailCardSubhead = ({ date, duration, companyLogo }) => {
  const startDate = dayJs.tz(date, 'America/Los_Angeles');
  const status = webinarTimeCompare(startDate, duration);
  const etDate = startDate.tz('America/New_York');

  return (
    <SeriesDetailCardSubheadContainer>
      <ul className="webinar-series-detail-card--date-container">
        {status === 0 ? (
          <li>
            <STIcon type="icon-live" />
            Live Now
          </li>
        ) : (
          <>
            <li>
              <STIcon type="icon-calendar" />
              {startDate.format('MMMM D, YYYY')}
            </li>
            <li>
              <STIcon type="icon-timer" />
              {status === -1
                ? webinarTimeDurationString(duration)
                : `${etDate.format('h:mmA')} ET / ${startDate.format('h:mmA')} PT`}
            </li>
          </>
        )}
      </ul>
      <div className="webinar-series-detail-card--logo-container">
        <WebsiteImage image={companyLogo} />
      </div>
    </SeriesDetailCardSubheadContainer>
  );
};

export default SeriesDetailCardSubhead;
