import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { google, outlook, yahoo, ics } from 'calendar-link';
import { STIcon } from 'components/Brand/Icons/STIcon';
import HyperLink from 'components/Core/HyperLink';
import Button from 'components/Core/Button';
import Dropdown from 'components/Core/Dropdown';

import { CalendarMenuItem } from './styles';

const AddToCalendarButton = ({ event, listItems, buttonLabel, buttonType }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuWidth, setMenuWidth] = useState(0);

  const defaultOptions = {
    apple: {
      label: 'Apple Calendar',
      url: () => (event ? ics(event) : ''),
      icon: <STIcon type="icon-apple" />,
    },
    google: {
      label: 'Google',
      url: () => (event ? google(event) : ''),
      icon: <STIcon type="icon-google" />,
    },
    outlook: {
      label: 'Outlook',
      url: () => (event ? ics(event) : ''),
      icon: <STIcon type="icon-windows" />,
    },
    outlookcom: {
      label: 'Outlook.com',
      url: () => (event ? outlook(event) : ''),
      icon: <STIcon type="icon-windows" />,
    },
    yahoo: {
      label: 'Yahoo',
      url: () => (event ? yahoo(event) : ''),
      icon: <STIcon type="icon-yahoo" />,
    },
  };

  const handleClick = (e) => {
    setAnchorEl(e?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (anchorEl) {
      setMenuWidth(anchorEl.clientWidth);
    }
  }, [anchorEl]);

  return (
    <>
      <Button type={buttonType || 'secondary'} button shadow={false} onDebouncedClick={handleClick}>
        {buttonLabel || 'Add To Calendar'}
      </Button>
      {listItems?.length > 0 && (
        <Dropdown
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
          PaperProps={{ style: { width: menuWidth } }}
          placement="bottom"
        >
          {listItems.map((item, index) => {
            const type = typeof item === 'string' ? item : item.type;
            if (!Object.keys(defaultOptions).includes(type)) {
              return null;
            }

            const label = item.label || defaultOptions[type].label;
            const { url, icon } = defaultOptions[type];

            return (
              <CalendarMenuItem key={index} data-testid="calendar-menu">
                <HyperLink
                  className="calendar-link"
                  target="_blank"
                  href={url()}
                  onClick={handleClose}
                >
                  {icon}
                  {label}
                </HyperLink>
              </CalendarMenuItem>
            );
          })}
        </Dropdown>
      )}
    </>
  );
};

AddToCalendarButton.propTypes = {
  buttonLabel: PropTypes.string,
  listItems: PropTypes.array,
};

AddToCalendarButton.defaultProps = {
  buttonLabel: 'Add to My Calendar',
  listItems: ['apple', 'google', 'outlook', 'outlookcom', 'yahoo'],
};

export default AddToCalendarButton;
