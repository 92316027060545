/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const DetailContentContainer = styled.div`
  .webinar-series-detail--wrapper {
    max-width: 1252px;
    margin: -45px auto 0;
    padding: 0 28px;
    display: flex;
  }

  .webinar-series-detail--content-container {
    flex: 8;
    margin-right: 40px;
  }

  .webinar-series-detail--sidebar-container {
    flex: 3;
    position: relative;
    padding-bottom: 40px;
  }

  @media (max-width: 1215px) {
    .webinar-series-detail--wrapper {
      padding: 0 20px;
    }

    .webinar-series-detail--content-container {
      margin-right: 20px;
    }
  }

  @media (max-width: 991px) {
    .webinar-series-detail--content-container {
      margin: 0;
    }
  }
`;
