import React from 'react';
import Modal from 'components/Core/Modal';
import Text from 'components/Core/Text';
import Button from 'components/Core/Button';
import { STIcon } from 'components/Brand/Icons/STIcon';

import { LiveModalContainer } from './styles';

const LiveModal = ({ open, onModalCancel, ...otherProps }) => {
  return (
    <Modal open={open} onClose={onModalCancel} handleClose={onModalCancel} {...otherProps}>
      <LiveModalContainer>
        <div className="webinar-series-detail--live-dialog-wrapper">
          <div className="webinar-series-detail--live-dialog-icon">
            <STIcon type="icon-email" />
          </div>
          <div className="webinar-series-detail--live-dialog-text">
            <Text type="h4">Please check your email</Text>
            <Text type="p">
              Your unique join link was sent this morning from "The Growth Series"
            </Text>
          </div>
          <div className="webinar-series-detail--live-dialog-button">
            <Button type="secondary" shadow={false} onClick={() => onModalCancel()}>
              Continue
            </Button>
          </div>
        </div>
      </LiveModalContainer>
    </Modal>
  );
};

export default LiveModal;
