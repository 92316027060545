import React from 'react';
import Button from 'components/Core/Button';
import dayJs, { webinarTimeCompare, webinarTimeDaysHoursLeft } from 'utils/date';
import useBreakpointView from 'utils/useBreakpointView';
import AddToCalendarButton from 'components/AddToCalendarButton';
import WistiaVideo from 'components/Core/WistiaVideo';
import { SeriesDetailCardFooterContainer } from './styles';

const TimeDetail = ({ value, label }) => {
  if (!value) {
    return null;
  }
  return (
    <span>
      <b>{value}</b> {label}
    </span>
  );
};

const SeriesDetailCardFooter = ({
  isFormSubmitted,
  info,
  handleOpenLiveModal,
  handleOpenRegisterModal,
  handleNoVideo,
  handleVideoClick,
}) => {
  const { webinarTitle, keyTakeaways, webinarUTCDate, time, wistiaVideo, wistiaVideoId } = info;
  const startDate = dayJs.tz(webinarUTCDate, 'America/Los_Angeles');
  const status = webinarTimeCompare(startDate, time);
  const [leftDay, leftDayLabel, leftHour, leftHourLabel] = webinarTimeDaysHoursLeft(startDate);
  const isMobile = useBreakpointView(['xs', 'sm']);
  const watchLabel = 'Watch Now';

  const ctaButton = () => {
    if (!isFormSubmitted) {
      return (
        <Button type="secondary" shadow={false} onClick={() => handleOpenRegisterModal()}>
          Register
        </Button>
      );
    }

    if (status === -1) {
      if (!wistiaVideo && !wistiaVideoId) {
        return (
          <Button type="secondary" shadow={false} onClick={() => handleNoVideo()}>
            {watchLabel}
          </Button>
        );
      }

      return (
        <WistiaVideo
          {...(wistiaVideo && { ...wistiaVideo })}
          {...(wistiaVideoId && {
            url: `https://servicetitan-1.wistia.com/medias/${wistiaVideoId}`,
          })}
          inline={false}
          customCta={
            <Button
              type="secondary"
              shadow={false}
              style={isMobile ? { pointerEvents: 'none' } : null}
            >
              {watchLabel}
            </Button>
          }
        />
      );
    }

    if (status === 0) {
      return (
        <Button type="secondary" shadow={false} onClick={() => handleOpenLiveModal()}>
          {watchLabel}
        </Button>
      );
    }

    return (
      <AddToCalendarButton
        event={{
          title: webinarTitle,
          description: keyTakeaways.join('\n'),
          location: 'ServiceTitan',
          start: startDate.format(),
          duration: [time || 0, 'minute'],
        }}
        buttonLabel="Add to Calendar"
      />
    );
  };

  return (
    <SeriesDetailCardFooterContainer>
      <div className="webinar-series-detail-card--left-time">
        <TimeDetail value={leftDay} label={leftDayLabel} />
        <TimeDetail value={leftHour} label={leftHourLabel} />
      </div>
      {ctaButton()}
    </SeriesDetailCardFooterContainer>
  );
};

export default SeriesDetailCardFooter;
