/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const LiveModalContainer = styled.div`
  padding: 20px;
  text-align: center;
  background: white;

  .webinar-series-detail--live-dialog-icon {
    padding: 30px 0;
  }

  .webinar-series-detail--live-dialog-text {
    max-width: 400px;
    margin: 0 auto;

    h4 {
      margin-bottom: 10px;
    }

    p {
      margin: 0;
    }
  }

  .webinar-series-detail--live-dialog-button {
    margin-top: 50px;
    text-align: right;
    display: flex;
    justify-content: center;
  }
`;
