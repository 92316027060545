import styled from 'styled-components';

export const SeriesDetailCardFooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  .webinar-series-detail-card--left-time {
    display: flex;
    align-items: center;
    margin-right: 15px;

    span {
      font-weight: var(--font-weight-500);
      font-size: var(--font-size-16);
      line-height: var(--line-height-150);
      display: flex;
      align-items: baseline;
      color: var(--normal-title--color);
      margin-right: 15px;
      text-transform: capitalize;
      padding: 10px 0;

      b {
        font-weight: var(--font-weight-900);
        font-size: var(--font-size-h4);
        line-height: var(--line-height-110);
        letter-spacing: var(--letter-spacing--0-03);
        color: var(--darkest);
        margin-right: 10px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .wistia-video--wrapper {
    display: flex;
    justify-content: flex-end;
  }

  a {
    height: 60px;
  }

  @media (max-width: 1215px) {
    flex-wrap: wrap;
  }

  @media (max-width: 767px) {
    justify-content: space-between;
  }

  @media (max-width: 575px) {
    flex-direction: column;

    .webinar-series-detail-card--left-time {
      margin: 0 0 5px;
      justify-content: center;
    }
  }
`;
