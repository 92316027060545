/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import HyperLink from 'components/Core/HyperLink';

export const SidebarContainer = styled.div`
  position: sticky;
  top: 140px;

  .webinar-series-sidebar--wrapper {
    background: var(--white);
    border: 1px solid var(--grey-3);
    box-sizing: border-box;
    box-shadow: 0px 3px 10px rgba(11, 26, 33, 0.08);
    border-radius: 8px;

    .webinar-series-sidebar--content-container {
      padding: 25px 0;

      .webinar-series-sidebar--title {
        font-weight: bold;
        padding: 0 25px;
        margin-bottom: 15px;
      }
    }
  }

  .webinar-series-sidebar--footer {
    margin-top: 25px;
  }

  .webinar-series-sidebar--social {
    position: absolute;
    right: -55px;
    top: 60px;

    a {
      width: 25px;
      height: 25px;
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const SidebarItem = styled(HyperLink)`
  padding: 9px 19px;
  border-left: 5px solid transparent;
  cursor: pointer;
  display: block;

  .webinar-series-sidebar--item-container {
    width: 260px;
    overflow: hidden;
    position: relative;

    p {
      font-weight: var(--font-weight-500);
      font-size: var(--font-size-16);
      line-height: var(--line-height-150);
      margin: 0;
      color: var(--darkest);
      white-space: nowrap;
    }

    &:before {
      content: ' ';
      width: 50px;
      background: linear-gradient(to right, #ffffff00, #ffffffff);
      position: absolute;
      height: 100%;
      right: 0;
      z-index: 10;
    }
  }

  &:hover {
    background: #f5fcff;

    .webinar-series-sidebar--item-container:before {
      display: none;
    }
  }

  &.active {
    border-left: 5px solid var(--titan-blue-3);

    p {
      font-weight: var(--font-weight-600);
      color: var(--titan-blue-3);
    }
  }

  @media (max-width: 1215px) {
    .webinar-series-sidebar--item-container {
      width: 200px;
    }
  }
`;
