/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const DetailCardContainer = styled.div`
  margin-bottom: 40px;

  .webinar-series-detail-card--wrapper {
    background: var(--white);
    border: 1px solid var(--grey-3);
    box-shadow: 0px 3px 10px rgba(11, 26, 33, 0.08);
    border-radius: 8px;
    display: flex;
    overflow: hidden;
  }

  .webinar-series-detail-card--photo {
    width: 372px;
    background-image: ${({ speakerPhoto }) => `url(${speakerPhoto})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    position: relative;
    overflow: hidden;
    transition: all 1s ease-in-out;

    .webinar-series-detail-card--photo-mobile {
      display: none;
    }

    .webinar-series-detail-card--speakers-container {
      position: absolute;
      display: flex;
      width: 100%;
      bottom: 20px;
      justify-content: left;
      padding-left: 10px;

      .webinar-series-detail-card--speakers-item {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin: 0 8px;
        border: 1px solid var(--grey-3);
        overflow: hidden;
        background-size: 120%;
        background-repeat: no-repeat;
        background-position: 2px -4px;
        background-color: var(--titan-blue-3);
        box-shadow: 0px 15px 40px rgba(11, 26, 33, 0.1);

        &.selected {
          border: 2px solid var(--titan-blue-3);
        }
      }
    }
  }

  .webinar-series-detail-card--content-container {
    flex: 1;
    padding: 20px 20px 20px 8px;

    .webinar-series-detail-card--text-container {
      padding-top: 30px;

      .webinar-series-detail-card--main-text {
        min-height: 300px;
      }

      .webinar-series-detail-card--features {
        padding-inline-start: 20px;
        margin-bottom: 30px;

        li {
          font-size: var(--font-size-16);
          font-weight: var(--font-weight-500);
          line-height: var(--line-height-120);
          color: var(--dark);
          margin-bottom: 10px;
          &::marker {
          }
        }
      }
    }
  }

  @media (max-width: 1215px) {
    .webinar-series-detail-card--photo {
      width: 40%;
    }
  }

  @media (max-width: 767px) {
    .webinar-series-detail-card--wrapper {
      flex-direction: column;
    }

    .webinar-series-detail-card--photo {
      flex: auto;
      width: 100%;
      padding-top: 68%;
    }

    .webinar-series-detail-card--content-container {
      padding: 20px;

      .webinar-series-detail-card--text-container {
        padding-top: 0;

        .webinar-series-detail-card--main-text {
          min-height: 0;
        }
      }
    }
  }

  @media (max-width: 575px) {
    .webinar-series-detail-card--photo {
      ${({ speakerPhotoMobile }) =>
        speakerPhotoMobile ? `background-image: url(${speakerPhotoMobile});` : ''}

      .webinar-series-detail-card--speakers-container {
        bottom: 10px;
        padding-left: 0;
      }
    }

    .webinar-series-detail-card--content-container {
      .webinar-series-detail-card--text-container {
        .webinar-series-detail-card--features {
          margin-bottom: 20px;
        }
      }
    }
  }
`;
