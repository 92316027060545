import React, { useState, useEffect } from 'react';
import Marquee from 'components/Core/Marquee';
import Text from 'components/Core/Text';
import GetDemo from 'components/CallToActions/GetDemo';

import { SidebarContainer, SidebarItem } from './styles';

const SeriesSidebar = ({ series, ...otherProps }) => {
  const [selIndex, setSelIndex] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const setActiveItemIndex = () => {
    const header = document.getElementsByClassName('website-header');
    const headerHeight = header && header.length > 0 ? header[0].clientHeight : 0;
    const windowInnerHeight = window.innerHeight;
    const topOffset = (windowInnerHeight - headerHeight) / 2 + 30; // Center of screen + Offset

    const offsetArray = series?.map((item, index) => {
      const anchor = `webinar_series_detail_card_${index}`;
      const cardElement = document.getElementById(anchor);
      const rect = cardElement.getBoundingClientRect();
      return { offset: rect.top };
    });

    if (offsetArray[0]?.offset > topOffset) {
      setSelIndex(-1);
    } else {
      offsetArray.forEach((offset, index) => {
        if (
          offset.offset < topOffset &&
          (offsetArray[index + 1]?.offset || topOffset + 100) > topOffset
        ) {
          setSelIndex(index);
        }
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', () => setActiveItemIndex());
  }, []);

  return (
    <SidebarContainer {...otherProps}>
      <div className="webinar-series-sidebar--wrapper">
        <div className="webinar-series-sidebar--content-container">
          <Text type="h6" className="webinar-series-sidebar--title">
            The Growth Series
          </Text>
          {series &&
            series.length > 0 &&
            series.map((info, index) => (
              <SidebarItem
                className={`webinar-series-sidebar--item ${index === selIndex ? 'active' : ''}`}
                href={`#webinar_series_detail_card_${index}`}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(-1)}
                key={index}
              >
                <div
                  className={`webinar-series-sidebar--item-container ${
                    hoveredIndex === index ? 'block' : '!hidden'
                  }`}
                >
                  <Marquee
                    mode="smooth"
                    speed={30}
                    className={`${hoveredIndex === index ? 'block' : '!hidden'}`}
                  >
                    <Text type="p">{info.webinarTitle}</Text>
                  </Marquee>
                </div>
                <div
                  className={`webinar-series-sidebar--item-container ${
                    hoveredIndex === index ? '!hidden' : 'block'
                  }`}
                >
                  <Text type="p">{info.webinarTitle}</Text>
                </div>
              </SidebarItem>
            ))}
        </div>
      </div>
      <div className="webinar-series-sidebar--footer">
        <GetDemo />
      </div>
    </SidebarContainer>
  );
};

export default SeriesSidebar;
