import React, { useState, useEffect } from 'react';
import TrackVisibility from 'react-on-screen';
import Cookies from 'js-cookie';
import ModalMarketingForm from 'components/Form/ModalMarketingForm';
import useBreakpointView from 'utils/useBreakpointView';
import snackbar from 'utils/snackbar';
import { getMarketoFormId, getStoredMarketoData } from 'utils/marketo';
import useMarketoFormData from 'hooks/useMarketoFormData';
import { init, saveForm } from 'services/marketoService';
import { WithUserContext } from 'utils/UserContext';
import { formSubmittedEvent } from 'services/dataLayerService';
import WebinarSeriesDetailCard from '../Card';
import WebinarSeriesSidebar from '../Sidebar';
import WebinarLiveModal from '../LiveModal';
import { DetailContentContainer } from './styles';

const SeriesDetailContent = ({
  series,
  inlineMarketingForm,
  onDemandForm,
  userInfo,
  ...otherProps
}) => {
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [openLiveModal, setOpenLiveModal] = useState(false);
  const isMobile = useBreakpointView(['xs', 'sm']);

  if (inlineMarketingForm) {
    inlineMarketingForm.id = getMarketoFormId(inlineMarketingForm?.marketoForm);
  }
  if (onDemandForm) {
    onDemandForm.id = getMarketoFormId(onDemandForm?.marketoForm);
  }

  const onDemandFormData = useMarketoFormData(onDemandForm?.id);

  useEffect(() => {
    const formSubmitted = Cookies.get('webinarSeriesFormSubmitted');
    setFormSubmitted(formSubmitted === 'true');
  }, []);

  useEffect(() => {
    init(inlineMarketingForm?.id);
  }, []);

  const handleVideoClick = (title) => {
    if (!onDemandForm) {
      return;
    }

    init(onDemandForm?.id);

    const formCookieData = getStoredMarketoData(onDemandFormData, userInfo);

    formSubmittedEvent({
      form_type: 'CQL Form',
      category: 'form',
      page_title: title,
      ...formCookieData,
    });

    saveForm({
      marketoFormId: onDemandForm?.id,
      ...formCookieData,
    });
  };

  const handleOpenRegisterModal = () => {
    setOpenRegisterModal(true);
  };

  const handleOpenLiveModal = () => {
    setOpenLiveModal(true);
  };

  const handleNoVideo = () => {
    snackbar('Recorded webinar coming soon, check back later for access.');
  };

  const onFormSuccess = () => {
    Cookies.set('webinarSeriesFormSubmitted', 'true');
    setFormSubmitted(true);
    setOpenRegisterModal(false);
  };

  return (
    <DetailContentContainer {...otherProps}>
      <div className="webinar-series-detail--wrapper">
        <div className="webinar-series-detail--content-container">
          {series &&
            series.length > 0 &&
            series.map((info, index) => (
              <TrackVisibility partialVisibility={isMobile} key={index}>
                <WebinarSeriesDetailCard
                  id={`webinar_series_detail_card_${index}`}
                  info={info}
                  isFormSubmitted={isFormSubmitted}
                  handleOpenRegisterModal={handleOpenRegisterModal}
                  handleOpenLiveModal={handleOpenLiveModal}
                  handleNoVideo={handleNoVideo}
                  handleVideoClick={handleVideoClick}
                />
              </TrackVisibility>
            ))}
        </div>
        <div className="webinar-series-detail--sidebar-container min-md">
          <WebinarSeriesSidebar series={series} />
        </div>
      </div>
      <ModalMarketingForm
        isModalOpen={openRegisterModal}
        onModalCancel={() => setOpenRegisterModal(false)}
        inlineMarketingForm={inlineMarketingForm}
        onFormSuccess={onFormSuccess}
        shouldRedirect={false}
      />
      <WebinarLiveModal open={openLiveModal} onModalCancel={() => setOpenLiveModal(false)} />
    </DetailContentContainer>
  );
};

export default WithUserContext(SeriesDetailContent);
