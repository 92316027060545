/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const SeriesDetailCardSubheadContainer = styled.div`
  position: relative;
  margin-bottom: 15px;

  .webinar-series-detail-card--date-container {
    padding-inline-start: 0;
    list-style: none;
    display: flex;
    margin-bottom: 0;

    li {
      display: flex;
      align-items: center;
      font-weight: var(--font-weight-500);
      font-size: var(--font-size-13);
      line-height: var(--line-height-150);
      color: var(--grey-8);
      text-transform: uppercase;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      svg {
        margin-right: 10px;
      }
    }
  }

  .webinar-series-detail-card--logo-container {
    position: absolute;
    right: 0;
    top: -30px;
    height: 50px;
    display: flex;
    align-items: center;

    img {
      max-height: 100%;
    }
  }

  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;

    .webinar-series-detail-card--date-container {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .webinar-series-detail-card--logo-container {
      position: relative;
      height: 40px;
      top: 0;
    }
  }

  @media (max-width: 575px) {
    .webinar-series-detail-card--date-container {
      li {
        margin-right: 10px;
        font-size: var(--font-size-13);
        letter-spacing: var(--letter-spacing--0-03);

        svg {
          margin-right: 5px;
        }
      }
    }
  }
`;
