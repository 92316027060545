import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import dayJs from 'utils/date';
import ToolboxLayout from 'layouts/toolboxLayout';
import WebinarSeriesHero from 'components/Page/WebinarSeries/Hero';
import WebinarSeriesContent from 'components/Page/WebinarSeries/Content';
import useIsClient from 'hooks/useIsClient';

const WebinarSeriesPage = (props) => {
  const [webinarSeries, setWebinarSeries] = useState([]);
  const seo = props?.data?.seo;
  const series = props?.data?.series?.nodes;
  const form = props?.data?.form;
  const onDemandForm = props?.data?.onDemandForm;
  const isClient = useIsClient();
  useEffect(() => {
    const upcoming = series
      .map((x, index) => ({ index, diffDay: dayJs().diff(x.webinarUTCDate, 'day'), ...x }))
      .filter((x) => x.diffDay <= 0 && x.diffDay >= -3)
      .map((x) => series.splice(x.index, 1).shift());

    setWebinarSeries([...upcoming, ...series]);
  }, []);

  return (
    <WebinarSeriesPageContainer>
      <ToolboxLayout contentfulSeo={seo} layout="toolbox" {...props}>
        <WebinarSeriesHero />
        {isClient && (
          <WebinarSeriesContent
            series={webinarSeries}
            inlineMarketingForm={form}
            onDemandForm={onDemandForm}
          />
        )}
      </ToolboxLayout>
    </WebinarSeriesPageContainer>
  );
};

export const WebinarSeriesPageQuery = graphql`
  query WebinarSeriesPageQuery {
    seo: contentfulSeo(internalName: { eq: "SEO - Webinar Series Page" }) {
      ...Seo
    }
    form: contentfulInlineMarketingForm(internalName: { eq: "Webinar Series Registration Form" }) {
      ...InlineMarketingForm
    }
    onDemandForm: contentfulInlineMarketingForm(internalName: { eq: "Webinar On-Demand" }) {
      ...InlineMarketingForm
    }
    series: allContentfulWebinar(
      filter: { seo: { pageUrl: { regex: "/growth-series/" } } }
      sort: { fields: [webinarDate], order: ASC }
    ) {
      nodes {
        ...Webinar
      }
    }
  }
`;

export default WebinarSeriesPage;

const WebinarSeriesPageContainer = styled.div`
  .subnav--right {
    .subscribe-modal-button--button {
      display: none;
    }
  }
`;
